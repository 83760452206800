/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
const SFtoSM = (sf) => {
  const sm = sf / 10.764;
  return sm.toFixed(2);
};

const removeLetters = (str) => str.replace(/[^\d.-]/g, '');

const parseOrientation = (orientation) => orientation
  .map((direction) => {
    if (direction === 'N') {
      return 'NORTH';
    }
    if (direction === 'S') {
      return 'SOUTH';
    }
    if (direction === 'E') {
      return 'EAST';
    }
    if (direction === 'W') {
      return 'WEST';
    }
  })
  .join(', ');

const numberWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1].split('+'));
    }
  }
  console.log('Query variable %s not found', variable);
};

const sortListings = (availabilityData) => {
  const sorted = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };

  const availabilityArray = Object.values(availabilityData);

  availabilityArray.forEach((listing) => {
    sorted[parseInt(removeLetters(listing.bedrooms), 10)].push(listing);
  });

  for (let idx = 0; idx < Object.keys(sorted).length; idx += 1) {
    sorted[idx] = sorted[idx].sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10));
  }

  return sorted[0].concat(sorted[1], sorted[2], sorted[3], sorted[4], sorted[5], sorted[6]);
};

const parseAdditionalFields = (additional_fields, target) => {
  const keyArray = Object.keys(additional_fields);
  for (let idx = 0; idx < keyArray.length; idx += 1) {
    if (additional_fields[keyArray[idx]].key === target) {
      return numberWithCommas(additional_fields[keyArray[idx]].value);
    }
  }
};

export {
  SFtoSM,
  parseOrientation,
  numberWithCommas,
  getQueryVariable,
  sortListings,
  removeLetters,
  parseAdditionalFields,
};

/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import RenderInBrowser from 'react-render-in-browser';
import MediaQuery from 'react-responsive';

import validateEmail from '../components/utils/validateEmail';
import Form from '../components/contact/form';
import MobileForm from '../components/contact/mobile-form';
import MobileSuccess from '../components/contact/mobile-success';
import Success from '../components/contact/success';
import { mediaMin } from '../styles/MediaQueries';
import variables from '../styles/Variables';
import PageNavigation from '../components/utils/page-navigation';
import Context from '../config/Context';

const RegisterPage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${variables.colors.darkRed};
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  background-color: ${variables.colors.lightGray};
  .mobile-back {
    position: fixed;
    width: 10vw;
    top: calc(20vh + 4px);
    left: 5vw;
    ${mediaMin.tablet`
        transform: translate(0);
        width: 7.5vw;
        left: 6.25vw;
    `}
  }
`;

const FixedSection = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  color: ${variables.colors.darkRed};
  left: 0;
  z-index: 1;
  height 100vh;
  width: 35vw;
  padding: 15vh 5% 0 10%;
  box-sizing: border-box;
  background-color: #fff;
  justify-content: flex-start;
  align-items: center;
  .section {
    opacity: ${(props) => (props.navActive ? '0' : '1')};
    display: flex;
    flex-direction: column;
    margin-bottom: 15%;
    text-align: start;
    width: 100%;
    transition: opacity .5s ease-in-out;
    &:last-child {
        margin-bottom: 0;
    }
    span, a{
      width: 100%;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 16px;
      margin-bottom: 22px;
    }
  }
    .logo {
        opacity: ${(props) => (props.navActive ? '0' : '1')};
        margin-bottom: 15%;
        transition: opacity .5s ease-in-out;
        width: 100%;
      }
      a {
        width: 100%;
        cursor: pointer;
        &:hover{
            text-decoration: none;
        }
    }
`;

const RequiredFlag = styled.div`
  height: 60px;
  width: ${(props) => (props.large ? '100%' : '25%')};
  padding: '1em 0.5em';
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20vw;
  ${mediaMin.desktop`
    margin-bottom: 45vh;
  `}
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: medium;
  transition: opacity 0.25s ease-in-out;
  opacity: ${(props) => (props.error ? '1' : '0')};
`;

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: false,
      name: '',
      address: '',
      email: '',
      phone: '',
      howHear: '',
      residence: '',
      represented: null,
      brokerName: '',
      brokerFirm: '',
      priceRange: '',
      errors: {
        name: false,
        email: false,
        phone: false,
        howHear: false,
        residence: false,
        represented: false,
        brokerName: false,
        brokerFirm: false,
        priceRange: false,
      },
      showForm: true,
      showSuccess: false,
      mountForm: true,
      mountSuccess: false,
    };
  }

  unMountForm = () => {
    this.setState(
      {
        showForm: false,
      },
      () =>
        setTimeout(() => {
          this.setState({
            mountForm: false,
          });
        }, 1000)
    );
  };

  mountSuccess = () => {
    this.setState(
      {
        mountSuccess: true,
      },
      () =>
        setTimeout(() => {
          this.setState({
            showSuccess: true,
          });
        }, 1000)
    );
  };

  handleChange = (e) => {
    const newState = this.state;
    const { target } = e;
    const { name, value } = target;
    newState[name] = value;
    this.setState(newState);
  };

  validateRow = (e) => {
    const { errors } = this.state;
    let returnVal = true;
    if (e.target.name === 'email') {
      errors[e.target.name] = !validateEmail(e.target.value);
      returnVal = validateEmail(e.target.value);
    } else if (e.target.name === 'phone') {
      returnVal = e.target.value.split(' ').join('').split('-').join('').length >= 10;
      errors[e.target.name] = !returnVal;
    } else if (e.target.name === 'address') {
      returnVal = true;
    } else {
      errors[e.target.name] = e.target.value === '';
      returnVal = !(e.target.value === '');
    }
    this.setState({ errors });
    return returnVal;
  };

  handleErrors = () => {
    const {
      broker,
      name,
      email,
      phone,
      howHear,
      residence,
      represented,
      brokerName,
      brokerFirm,
      priceRange,
    } = this.state;
    const newErrors = {
      name: false,
      email: false,
      phone: false,
      howHear: false,
      residence: false,
      represented: false,
      brokerName: false,
      brokerFirm: false,
      priceRange: false,
    };
    if (name === '') {
      newErrors.name = true;
    }
    if (!validateEmail(email)) {
      newErrors.email = true;
    }
    if (phone.length < 10) {
      newErrors.phone = true;
    }
    if (howHear === '') {
      newErrors.howHear = true;
    }
    if (residence === '') {
      newErrors.residence = true;
    }
    if (priceRange === '') {
      newErrors.priceRange = true;
    }
    if (!broker) {
      if (represented === null) {
        newErrors.represented = true;
      }
      if (JSON.parse(represented)) {
        if (brokerName === '') {
          newErrors.brokerName = true;
        }
        if (brokerFirm === '') {
          newErrors.brokerFirm = true;
        }
      }
    }
    this.setState({
      errors: newErrors,
    });
    return Object.values(newErrors).some((el) => el);
  };

  firstName = () => {
    const { name } = this.state;
    const nameArray = name.split(' ');
    nameArray.pop();
    return nameArray.join(' ');
  };

  lastName = () => {
    const { name } = this.state;
    const nameArray = name.split(' ');
    return nameArray.pop();
  };

  parseFormData = () => {
    const {
      email,
      phone,
      address,
      represented,
      broker,
      brokerName,
      brokerFirm,
      howHear,
      residence,
      priceRange,
    } = this.state;

    const parsed = {
      projectname: 'madisonhousenyc',
      contact: {
        answers_attributes: {
          6067: { answers: [] },
          6068: { answers: [] },
          6069: { answers: [] },
          6070: { answers: [] },
          6071: { answers: [] },
          6072: { answers: [] },
        },
      },
    };
    parsed.contact.first_name = this.firstName();
    parsed.contact.last_name = this.lastName();
    parsed.contact.email = email;
    parsed.contact.phone = phone;
    parsed.contact.address_line_1 = address;
    parsed.contact.answers_attributes['6067'].answers = [howHear];
    parsed.contact.answers_attributes['6068'].answers = [residence];
    parsed.contact.answers_attributes['6069'].answers = [priceRange];
    parsed.contact.answers_attributes['6070'].answers = [represented];
    parsed.contact.answers_attributes['6071'].answers = [brokerName];
    parsed.contact.answers_attributes['6072'].answers = [brokerFirm];
    parsed.contact.agent = broker;
    if (broker) {
      parsed.contact.rating_id = '31656';
    } else {
      parsed.contact.rating_id = '31655';
    }
    parsed.contact.supplied_source = broker
      ? 'Broker: Broker Web Registrant'
      : 'Buyer: Prospect Web Registrant';
    return parsed;
  };

  submitForm = async (e) => {
    e.preventDefault();

    // Infinity Tracking
    /*eslint no-undef: 1*/
    _ictt.push(['_autoPopulateInput', { id: 'ictvid', item: 'ictvid' }]);
    if (!this.handleErrors()) {
      this.unMountForm();
      setTimeout(this.mountSuccess, 1000);
      $.ajax({
        url: 'https://form.api.dbxd.com/post-spark-form/',
        type: 'POST',
        dataType: 'json',
        data: this.parseFormData(),
        crossDomain: true,
        success: (/* res  textStatus, jqXHR */) => {
          console.log('success - form submitted');
        },
        error: (jqXHR, textStatus, errorThrown) => {
          console.log('http request failed', errorThrown);
        },
      });
      // this.sendDataToGoogleSheet();
    }
  };

  sendDataToGoogleSheet = () => {
    const {
      broker,
      address,
      email,
      phone,
      howHear,
      residence,
      represented,
      brokerName,
      brokerFirm,
      priceRange,
    } = this.state;
    $.ajax({
      url:
        'https://script.google.com/macros/s/AKfycby_ImhZHhrMHkqLwt5emEjEwgy2nrKWHty3yaexdKJuWqhfPL0/exec',
      method: 'GET',
      dataType: 'json',
      data: {
        firstname: this.firstName(),
        lastname: this.lastName(),
        client_type: JSON.parse(broker) ? 'Broker' : 'Buyer',
        email,
        address,
        homephone: phone,
        cellphone: phone,
        hasbroker: represented,
        realtor_name: brokerName,
        brokerage_company: brokerFirm,
        question_hearfrom: howHear,
        question_pricerange: priceRange,
        question_floorplans: residence,
      },
      success: (res, textStatus, jqXHR) => {
        console.log('Form Submission Successful - Google Sheet');
        console.log(res);
        console.log(textStatus);
        console.log(jqXHR);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.log('http request failed', errorThrown);
      },
    });
  };

  sendConfirmationEmail = () => {
    const { name, email } = this.state;
    const emailData = {
      from: '"Madison House Team" <no_reply_madisonhouse@dbox.com>',
      to: `"${name}" <${email}>`,
      subject: 'Thank you for your interest in Madison House.',
      htmlUrl: 'https://s3.amazonaws.com/madisonhousenyc.com/registration_email/index.html',
    };
    $.ajax({
      url: 'https://form.api.dbxd.com/send-ses-email',
      data: emailData,
      crossDomain: true,
      success(result) {
        console.log('confirmation email sending successful ', result);
      },
      error(err) {
        console.log('error sending confirmation email ', err);
      },
    });
  };

  sendNotificationEmail = () => {
    const {
      broker,
      name,
      address,
      email,
      phone,
      howHear,
      residence,
      represented,
      brokerName,
      brokerFirm,
      priceRange,
    } = this.state;
    const emailData = {
      from: '"Madison House Team" <no_reply_madisonhouse@dbox.com>',
      to: '"Madison House Team"  <info@madisonhousenyc.com>',
      subject: 'New Registrant from Madisonhouse.com.',
      text: `
                New Registrant!\n\n
                Broker: ${
                  JSON.parse(broker)
                    ? `Yes\n\n
                      Brokerage: ${brokerFirm}\n\n`
                    : 'No'
                }\n\n
                Name: ${name}\n\n
                Address: ${address}\n\n
                Email: ${email}\n\n
                Phone: ${phone}\n\n
                Heard From: ${howHear}\n\n
                Interested in: ${residence}\n\n
                Price Range: ${priceRange}\n\n
                ${
                  JSON.parse(represented)
                    ? `Represented By: ${brokerName}\n\n Brokerage: ${brokerFirm}\n\n`
                    : ''
                }`,
    };
    $.ajax({
      url: 'https://form.api.dbxd.com/send-ses-email',
      data: emailData,
      crossDomain: true,
      success: (result) => {
        console.log('notification email sending successful ', result);
      },
      error: (err) => {
        console.log('error sending notification email ', err);
      },
    });
  };

  render() {
    const {
      broker,
      name,
      address,
      email,
      phone,
      howHear,
      residence,
      represented,
      brokerName,
      brokerFirm,
      priceRange,
      errors,
      showForm,
      showSuccess,
      mountForm,
      mountSuccess,
      suggestions,
    } = this.state;
    return (
      <Context.Consumer>
        {(context) => (
          <RegisterPage mountSuccess={mountSuccess}>
            <MediaQuery minWidth={1024}>
              <>
                <FixedSection
                  navActive={context.navigationState}
                  showForm={showForm}
                  showSuccess={showSuccess}
                >
                  <Link to="/">
                    <img
                      className="logo"
                      src="/images/logos/madison-house-black.svg"
                      alt="Madison House Logo"
                    />
                  </Link>
                  <div className="section">
                    <a href="mailto:info@madisonhousenyc.com">
                      <span>INFO@MADISONHOUSENYC.COM</span>
                    </a>
                    <a className="InfinityNumber clickable" href="tel:2126861530">
                      <span className="InfinityNumber">212.686.1530</span>
                    </a>
                  </div>
                  <div className="section">
                    <span>SALES GALLERY</span>
                    <span>15 E 30TH STREET</span>
                    <span>NEW YORK, NEW YORK, 10016</span>
                  </div>
                  <div className="section">
                    <a
                      href="https://www.instagram.com/madisonhousenyc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>INSTAGRAM</span>
                    </a>
                  </div>
                  <div className="section">
                    <Link to="/legal">
                      <span>LEGAL</span>
                    </Link>
                  </div>
                </FixedSection>
                {mountForm ? (
                  <>
                    <RenderInBrowser except ie>
                      <Form
                        broker={broker}
                        name={name}
                        address={address}
                        email={email}
                        phone={phone}
                        howHear={howHear}
                        residence={residence}
                        represented={represented}
                        brokerName={brokerName}
                        brokerFirm={brokerFirm}
                        priceRange={priceRange}
                        errors={errors}
                        showForm={showForm}
                        handleChange={this.handleChange}
                        submitForm={this.submitForm}
                        validateRow={this.validateRow}
                        suggestions={suggestions}
                        setEmailSubmitted={context.setEmailSubmitted}
                        setEmailFormMessageVisable={context.setEmailFormMessageVisable}
                      />
                    </RenderInBrowser>
                    <RenderInBrowser ie only>
                      <MobileForm
                        broker={broker}
                        name={name}
                        address={address}
                        email={email}
                        phone={phone}
                        howHear={howHear}
                        residence={residence}
                        represented={represented}
                        brokerName={brokerName}
                        brokerFirm={brokerFirm}
                        priceRange={priceRange}
                        errors={errors}
                        showForm={showForm}
                        handleChange={this.handleChange}
                        submitForm={this.submitForm}
                        validateRow={this.validateRow}
                        suggestions={suggestions}
                        setEmailSubmitted={context.setEmailSubmitted}
                        setEmailFormMessageVisable={context.setEmailFormMessageVisable}
                      />
                    </RenderInBrowser>
                  </>
                ) : (
                  ''
                )}
                {mountSuccess ? <Success showSuccess={showSuccess} /> : ''}
                <PageNavigation prevPage="PRESS" prevPath="/nomad-real-estate-news" />
              </>
            </MediaQuery>
            <MediaQuery maxWidth={1023}>
              <>
                {mountForm ? (
                  <>
                    <MobileForm
                      broker={broker}
                      name={name}
                      address={address}
                      email={email}
                      phone={phone}
                      howHear={howHear}
                      residence={residence}
                      represented={represented}
                      brokerName={brokerName}
                      brokerFirm={brokerFirm}
                      priceRange={priceRange}
                      errors={errors}
                      showForm={showForm}
                      handleChange={this.handleChange}
                      submitForm={this.submitForm}
                      validateRow={this.validateRow}
                      suggestions={suggestions}
                      setEmailSubmitted={context.setEmailSubmitted}
                      setEmailFormMessageVisable={context.setEmailFormMessageVisable}
                    />
                    <RequiredFlag
                      aria-hidden={!Object.values(errors).some((el) => el)}
                      error={Object.values(errors).some((el) => el)}
                      large
                    >
                      *FIELDS ARE REQUIRED
                    </RequiredFlag>
                  </>
                ) : (
                  ''
                )}
                {mountSuccess ? <MobileSuccess showSuccess={showSuccess} /> : ''}
              </>
            </MediaQuery>
          </RegisterPage>
        )}
      </Context.Consumer>
    );
  }
}
